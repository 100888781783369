@media screen and (max-width: 10000px) {

	/* 分辨率在480～1920px样式 */
	.footer {
		width: 960px;
		margin: 0 auto;
		overflow: hidden;
		text-align: center;
		opacity: 1;
		background: #F5F6F7;
		line-height: 17px;
		/* font-family: PingFangSC-Regular; */
		font-size: 12px;
		color: #000000;
		font-weight: normal;
	}

	.split {
		margin-bottom: 30px;
		position: relative;
	}

	.split:after {
		content: "  ";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 1px;
		opacity: 0.15;
		background: #000000;
		/* 如果不用 background-color, 使用 border-top:1px solid #e0e0e0; */
		-webkit-transform: scaleY(.5);
		transform: scaleY(.5);
	}

	.socialWrap {
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0.5;
		letter-spacing: 0px;
		margin-bottom: 16px;
	}

	.feebackFooter {
		margin-right: 20px;
	}

	.copyright {
		opacity: 0.4;
		margin-bottom: 16px;
	}

	.icpWrap {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;
	}

	.icpFooter {
		opacity: 0.4;
		margin-right: 20px;
	}

	.pnsIcon {
		display: inline-block;
		margin-right: 6px;
		width: 14px;
		height: 14px;
		opacity: 1;
		background-image: url(../../assets/pns@3X.png);
		background-size: 14px 14px;
	}

	.psnRecord {
		opacity: 0.4;
	}


	.icpFooter:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	.psnRecord:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}


@media screen and (max-width: 620px) {

	/* 分辨率在0～620px样式 */
	.footer {
		width: 100%;
		margin: 0 auto;
		line-height: 0.17rem;
		overflow: hidden;
		text-align: center;
		opacity: 1;
		background: #F5F6F7;
		/* font-family: PingFangSC-Regular; */
		font-size: 0.12rem;
		color: #000000;
		font-weight: normal;
	}

	.split {
		margin-bottom:0rem 0.25rem 0.3rem;
		position: relative;
	}

	.split:after {
		content: "  ";
		position: absolute;
		left:0.25rem;
		top: 0;
		width: calc(100% - 0.5rem);
		height: 1px;
		opacity: 0.15;
		background: #000000;
		-webkit-transform: scaleY(0.5);
		transform: scaleY(0.5);
	}

	.socialWrap {
		display: inline-block;
		align-items: center;
		justify-content: center;
		text-align: center;
		opacity: 0.5;
		letter-spacing: 0px;
		margin-bottom: 0.16rem;
	}

	.feebackFooter {
		display: block;
		margin-right: 0rem;
		margin-bottom: 0.16rem;
	}

	.socialMediaAccount {
		display: block;
	}

	.copyright {
		opacity: 0.4;
		margin-bottom: 0.16rem;
	}

	.icpWrap {
		display: flex;
		opacity: 1;
		justify-content: center;
		align-items: center;
		margin-bottom: 0.3rem;
	}

	.icpFooter {
		opacity: 0.4;
		margin-right: 0.2rem;
	}

	.pnsIcon {
		display: inline-block;
		margin-right: 0.06rem;
		width: 0.14rem;
		height: 0.14rem;
		opacity: 1;
		background-image: url(../../assets/pns@3X.png);
		background-size: 0.14rem 0.14rem;
	}

	.psnRecord {
		opacity: 0.4;
	}

	.icpFooter:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	.psnRecord:hover {
		cursor: pointer;
		text-decoration: underline;
	}

}