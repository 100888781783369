@media screen and (max-width: 10000px) {

	/* 分辨率在480～1920px样式 */
	.header {
		width: 100%;
		height: 60px;
		opacity: 1;
		background: #FFFFFF;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
	}

	.headerBar {
		display: flex;
		width: 960px;
		margin: 0 auto;
		align-items: center;
		height: 60px;
		opacity: 1;
		background: #FFFFFF;
		justify-content: space-between;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
	}

	.appName {
		display: flex;
		align-items: center;
		/* font-family: PingFangSC-Regular; */
		font-size: 20px;
		font-weight: normal;
		line-height: 24px;
		white-space: nowrap;
		letter-spacing: 0px;
		color: #FF8093;
	}


	.appNameLogo {
		display: inline-block;
		height: 24px;
		width: 24px;
		margin-right: 14px;
		background-size: 24px 24px;
		background-image: url(../../assets/logo@3X.png);
	}

	.languageSwitch {
		padding: 0px 9px;
		height: 31px;
		cursor: pointer;
		position: relative;
		/* font-family: SFProText-Regular-Regular; */
		font-size: 18px;
		font-weight: normal;
		line-height: 31px;
		text-align: center;
		letter-spacing: 0px;
		color: #808080;
		background-color: #FFFFFF;
		border-radius: 3px;
		opacity: 1;
		-webkit-tap-highlight-color: transparent;
	}

	.languageSwitch:after {
		content: "  ";
		position: absolute;
		left: calc(-50% - 1px);
		top: calc(-50% - 1px);
		width: 200%;
		height: 200%;
		opacity: 1;
		border: 1px solid #999999;
		border-radius: 3px;
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	.languageSwitch:hover {
		color: #333;
	}

	.languageSwitch:hover.languageSwitch:after {
		border-color: #333;
	}
}


@media screen and (max-width: 620px) {

	/* 分辨率在0～620px样式 */
	.header {
		width: 100%;
		height: 0.64rem;
		opacity: 1;
		background: #FFFFFF;
		justify-content: center;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
	}

	.headerBar {
		display: flex;
		width: 100%;
		align-items: center;
		height: 0.64rem;
		opacity: 1;
		background: #FFFFFF;
		justify-content: space-between;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
	}


	.appName {
		display: flex;
		align-items: center;
		margin-left: 0.18rem;
		margin-right: auto;
		/* font-family: PingFangSC-Regular; */
		font-size: 0.2rem;
		font-weight: normal;
		line-height: 0.24;
		white-space: nowrap;
		letter-spacing: 0px;
		color: #FF8093;
	}

	.appNameLogo {
		display: inline-block;
		height: 0.24rem;
		width: 0.24rem;
		margin-right: 0.12rem;
		background-size: 0.24rem 0.24rem;
		background-image: url(../../assets/logo@3X.png);
	}

	.languageSwitch {
		padding: 0rem 0.07rem;
		height: 0.29rem;
		margin-right: 0.18rem;
		cursor: pointer;
		position: relative;
		background-color: #FFFFFF;
		-webkit-tap-highlight-color:transparent;
		/* font-family: SFProText-Regular-Regular; */
		font-size: 0.16rem;
		font-weight: normal;
		line-height: 0.29rem;
		text-align: center;
		letter-spacing: 0rem;
		/* color: #808080; */
		opacity: 1;
	}

	.languageSwitch:after {
		content: "  ";
		position: absolute;
		left: calc(-50% - 1px);
		top: calc(-50% - 1px);
		width: 200%;
		height: 200%;
		opacity: 1;
		border: 1px solid #999999;
		border-radius: 0.04rem;
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	.languageSwitchHover {
		padding: 0rem 0.09rem;
		height: 0.29rem;
		margin-right: 0.18rem;
		cursor: pointer;
		position: relative;
		background-color: #FFFFFF;
		-webkit-tap-highlight-color:transparent;
		/* font-family: SFProText-Regular-Regular; */
		font-size: 0.18rem;
		font-weight: normal;
		line-height: 0.29rem;
		text-align: center;
		letter-spacing: 0rem;
		/* color: #808080; */
		border-radius: 0.03rem;
		opacity: 1;
		color: #333;
	}

	.languageSwitchHover:after {
		content: "  ";
		position: absolute;
		left: calc(-50% - 1px);
		top: calc(-50% - 1px);
		width: 200%;
		height: 200%;
		opacity: 1;
		border: 1px solid  #333;
		border-radius: 0.02rem;
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
}