@media screen and (max-width: 10000px) {

	/* 分辨率在480～1920px样式 */
	.todoContent {
		width: 960px;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		overflow: hidden;
		text-align: center;
		opacity: 1;
		background: #F5F6F7;

	}

	.introHeading {
		margin: 50px 0px 34px;
		opacity: 1;
		/* font-family: PingFangSC-Medium; */
		font-size: 40px;
		font-weight: 700;
		line-height: 48px;
		text-align: center;
		letter-spacing: 0px;
		color: #000000;
	}

	.introNotes {
		text-align: center;
		opacity: 0.6;
		/* font-family: PingFangSC-Regular; */
		font-size: 15px;
		line-height: 24px;
		font-weight: normal;
		text-align: center;
		letter-spacing: 0px;
		color: #333;
		white-space: pre-wrap
	}

	.downloadAppGroupWrap {
		margin: 50px auto 64px;
		display: flex;
		height: 44px;
	}

	.downloadAppWrap {
		text-decoration: none;
		margin: auto 20px;
		height: 44px;
		padding: 0px 25.5px;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 3px;
		opacity: 1;
		background: #FF8093;
		box-shadow: 0px 2px 5px 0px rgba(2, 26, 0, 0.15);
	}

	.downloadAppWrap:hover {
		opacity: 0.8;
	}

	.iosLogo {
		display: inline-block;
		width: 16px;
		height: 19px;
		margin-right: 10px;
		opacity: 1;
		background-size: 16px 19px;
		background-image: url('../../assets/apple_logo@3X.png');
	}

	.androidLogo {
		display: inline-block;
		width: 17px;
		height: 20.7px;
		margin-right: 10px;
		opacity: 1;
		background-size: 17px 20.7px;
		background-image: url('../../assets/android@3X.png');
	}

	.downloadInfo {
		opacity: 1;
		/* font-family: PingFangSC-Regular; */
		font-size: 16px;
		font-weight: normal;
		letter-spacing: 0px;
		color: #FFFFFF;
	}

	.toToAppImgWrap {
		display: flex;
		height: 562px;
	}

	.toToAppImgHome {
		width: 235px;
		height: 442.58px;
		margin: 0px 214px 0px 138px;
		opacity: 1;
		background-size: 235px 442.58px;
	}

	.toToAppImgHomeZh {
		background-image: url(../../assets/zhuiqiu@3X.png);
		background-repeat: no-repeat;
	}

	.toToAppImgPast {
		width: 235px;
		height: 442.58px;
		opacity: 1;
		background-size: 235px 442.58px;
	}

	.toToAppImgPastZh {
		background-image: url(../../assets/tuijian@3X.png);
		background-repeat: no-repeat;
	}

	/* .detailsWrap {
		margin: 0px 240px;
	} */

	.paperSimplenessNotes {
		opacity: 0.5;
		/* font-family: PingFangSC-Regular; */
		font-size: 14px;
		font-weight: normal;
		line-height: 26px;
		text-align: justify;
		/* 浏览器可能不支持 */
		letter-spacing: 0px;
		color: #000000;
		white-space: pre-wrap
	}

	.suitableUsersNotes {
		margin-bottom: 70px;
		line-height: 28px;
	}

	.aboutUsNotes {
		margin-bottom: 141px;
		line-height: 28px;
	}
}

@media screen and (max-width: 620px) {

	/* 分辨率在0～480px样式 */
	.todoContent {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		overflow: hidden;
		text-align: center;
		opacity: 1;
		background: #F5F6F7;
	}

	.introHeading {
		margin: 0.5rem 0rem 0.24rem;
		opacity: 1;
		/* font-family: PingFangSC-Medium; */
		font-size: 0.26rem;
		font-weight: 700;
		line-height: 0.36rem;
		text-align: center;
		letter-spacing: 0px;
		color: #000000;
	}

	.introNotes {
		text-align: center;
		opacity: 0.6;
		/* font-family: PingFangSC-Regular; */
		font-size: 0.15rem;
		line-height: 0.24rem;
		font-weight: normal;
		text-align: center;
		letter-spacing: 0px;
		color: #000000;
		white-space: pre-wrap
	}

	.downloadAppGroupWrap {
		display: flex;
		position: relative;
		height: 0.44rem;
	}

	.downloadAppWrap {
		text-decoration: none;
		margin: auto 0.2rem;
		height: 0.44rem;
		padding: 0rem 0.255rem;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 3px;
		opacity: 1;
		background: #FF8093;
		box-shadow: 0px 2px 5px 0px rgba(2, 26, 0, 0.15);
	}

	.downloadAppWrap:hover {
		opacity: 0.8;
	}

	.iosLogo {
		display: inline-block;
		width: 16px;
		height: 19px;
		margin-right: 10px;
		opacity: 1;
		background-size: 16px 19px;
		background-image: url('../../assets/apple_logo@3X.png');
	}

	.androidLogo {
		display: inline-block;
		width: 17px;
		height: 20.7px;
		margin-right: 10px;
		opacity: 1;
		background-size: 17px 20.7px;
		background-image: url('../../assets/android@3X.png');
	}

	.downloadInfo {
		opacity: 1;
		/* font-family: PingFangSC-Regular; */
		font-size: 0.16rem;
		font-weight: normal;
		letter-spacing: 0px;
		color: #FFFFFF;
	}

	.toToAppImgWrap {
		display: flex;
		position: relative;
		height: 5.0rem;
	}

	.toToAppImgHome {
		width: 1.8rem;
		height: 3.39rem;
		position: absolute;
		top: 0rem;
		left: -0.1rem;
		margin: 0rem 0.5rem 0rem 0rem;
		opacity: 1;
		background-size: 1.8rem 3.39rem;
	}

	.toToAppImgHomeZh {
		background-image: url(../../assets/zhuiqiu@3X.png);
	}

	.toToAppImgPast {
		width: 1.8rem;
		height: 3.39rem;
		opacity: 1;
		position: absolute;
		top: 0rem;
		right: -0.1rem;
		background-size: 1.8rem 3.39rem;
	}

	.toToAppImgPastZh {
		background-image: url(../../assets/tuijian@3X.png);
	}

	/* .detailsWrap {
		margin: 0px 240px;
	} */

	.someFeatures,
	.suitableUsers,
	.aboutUs {
		margin: 0.7rem 0rem 0.32rem 0.25rem;
		text-align: left;
		opacity: 1;
		/* font-family: PingFangSC-Medium; */
		font-size: 0.24rem;
		height: 0.34rem;
		line-height: 0.34rem;
		font-weight: 700;
		letter-spacing: 0px;
		color: #000000;
	}

	.someFeatures {
		margin-bottom: 0.34rem;
	}

	.featureWrap {
		display: flex;
		flex-direction: column;
		margin-bottom: 0rem;
		align-items: center;
		justify-content: center;
	}

	.featureItemWrap {
		margin: 0 auto 0.32rem;
		width: 3.4rem;
	}

	.featureItemWrap:nth-of-type(3) {
		margin-right: 0.25rem;
	}

	.featureImg1 {
		width: 3.4rem;
		height: 2.38rem;
		opacity: 1;
		background-size: 3.4rem 2.38rem;
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
	}

	.featureImg2 {
		width: 3.4rem;
		height: 2.38rem;
		opacity: 1;
		background-size: 3.4rem 2.38rem;
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
	}

	.featureImg3 {
		width: 3.4rem;
		height: 2.38rem;
		opacity: 1;
		background-size: 3.4rem 2.38rem;
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
	}

	.featuresTitle {
		margin-top: 0.22rem;
		margin-bottom: 0.11rem;
		text-align: left;
		line-height: 0.28rem;
		opacity: 1;
		/* font-family: PingFangSC-Medium; */
		font-size: 0.2rem;
		font-weight: 700;
		letter-spacing: 0px;
		color: #000000;
	}

	.paperSimplenessNotes {
		opacity: 0.5;
		/* font-family: PingFangSC-Regular; */
		font-size: 0.15rem;
		font-weight: normal;
		line-height: 0.26rem;
		text-align: justify;
		/* 浏览器可能不支持 */
		letter-spacing: 0rem;
		color: #000000;
		white-space: pre-wrap
	}

	.suitableUsersNotes {
		margin-bottom: 0.7rem;
		margin: 0rem 0.25rem;
	}

	.aboutUsNotes {
		margin: 0rem 0.25rem 1.4rem;
	}
}